@include rmin(1025) {
	* {
		cursor: none; }
	a, button {
		cursor: none; } }
html {
	width: 100vw;
	height: 100vh;
	overflow: hidden; }
body {
	width: 100vw;
	height: 100vh;
	overflow: hidden;
	position: fixed;
	@include smooth-font;
	font-family: -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'helvetica neue', helvetica, ubuntu, roboto, noto, 'segoe ui', arial, sans-serif;
	&.is-disabled {
		pointer-events: none; }
	@include rmin(1025) {
		* {
			cursor: none; }
		a, button {
			cursor: none; } } }

body {
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    user-select: none; }

.rect {
	width: 60vw;
	height: 60vh;
	@include vcenter;
	background: #000;
	@media screen and (max-width: 1024px) {
		width: 80vw;
		height: 40vh;
		margin-top: -40px; }
	@media screen and (max-width: 1024px) and (orientation: landscape) {
		width: 60vw;
		height: 60vh; }
	&.is-changed {
		top: 0;
		left: 0;
		transform: translate(0, 0);
		width: 100%; } }

.video-block {
	&__in {
		z-index: 200; }
	&.is-hidden {
		iframe {
			display: none; } } }

.canvas {
	position: absolute;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	overflow: hidden; }

.rainbow {
  background-color: #ffffff;
  background-image: -webkit-linear-gradient(90deg, #00a2e2 0%, #1bad6b 20%, #fec609 40%, #e92687 60%, #6d358d 80%, #00a2e2 100%);
  background-image: -moz-linear-gradient(90deg, #00a2e2 0%, #1bad6b 20%, #fec609 40%, #e92687 60%, #6d358d 80%, #00a2e2 100%);
  background-image: -o-linear-gradient(90deg, #00a2e2 0%, #1bad6b 20%, #fec609 40%, #e92687 60%, #6d358d 80%, #00a2e2 100%);
  background-image: linear-gradient(90deg, #00a2e2 0%, #1bad6b 20%, #fec609 40%, #e92687 60%, #6d358d 80%, #00a2e2 100%);
  background-size: 200% auto;
  color: #000;
  background-clip: text;
  text-fill-color: transparent;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  animation: rainbow 3s linear infinite;


  @keyframes rainbow {
    to {
        background-position: 200% center; } } }














