.homelist {
	height: 100%;
	&.is-disabled {
		pointer-events: none; }
	&__msg {
		position: absolute;
		top: 50%;
		left: 50px;
		transform: translate(0, -50%);
		color: #fff;
		font-size: 14px;
		@include r(1024) {
			display: none; } }
	&__in {
		height: 100%;
		&.no-transform {
			transform: none !important;
			.homelist__slider:not(.homelist__slider--clip) {
				a {
					white-space: initial; } } } }
	&__slider {
		height: 100%;
		&--clip {
			@include coverdiv;
			z-index: 2;
			pointer-events: none;
			.homelist__slide {
				a {
					-webkit-text-fill-color: #fff; } } } }
	&__slide {
		width: auto;
		min-width: 70%;
		max-width: 100%;
		position: relative;
		@include xs {
			min-width: 100%; }
		a {
			@include vcenter;
			display: flex;
			justify-content: space-between;
			text-align: center;
			font-size: 9vw;
			text-decoration: none;
			color: #fff;
			-webkit-text-stroke: 1px #fff;
			-webkit-text-fill-color: transparent;
			transform-origin: 0 50%;
			white-space: nowrap;
			@include r(1024) {
				margin-top: -40px; }
			@include xs {
				font-size: 12vw; } } }

	&__scrollbar {
		width: 25vw;
		height: 9px;
		position: absolute;
		z-index: 10;
		left: 50%;
		bottom: 8vh;
		transform: translate(-50%, 0);
		@include r(1024) {
			width: 50vw;
			bottom: 100px; }
		&:before {
			content: "";
			width: 100%;
			height: 1px;
			display: block;
			background: #4d4d4d;
			position: absolute;
			left: 0;
			top: 4px; } }
	&__drag {
		width: 100%;
		height: 19px;
		position: relative;
		top: -4px;
		left: 0;
		&:hover {
			.homelist__handle:before {
				transform: scaleY(2);
				transform-origin: 50% 50%; } } }
	&__handle {
		@include coverdiv;
		&:before {
			content: "";
			position: absolute;
			top: 7px;
			left: 0;
			width: 100%;
			height: 3px;
			background: #fff;
			@include trans(transform); } }

	&__rect {
		z-index: -1;
		background: transparent; }
	.swiper-container {
		@include coverdiv; }
	.num {
		margin-right: em(8);
		@include r(1024) {
			font-size: 20px; }
		@include xs {
			font-size: 12px;
			margin-right: em(20); } } }
