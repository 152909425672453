.projectlist {
	height: 100vh;
	.swiper-wrapper {
		height: auto; }
	&.is-disabled {
		pointer-events: none; }
	&__header {
		position: relative; }
	&__title {
		font-size: 6vw;
		line-height: 1.13;
		-webkit-text-stroke: 1px #000;
		-webkit-text-fill-color: transparent;
		&--clip {
			@include coverdiv;
			-webkit-text-fill-color: #000; } }
	&__slider {
		height: 100%;
		padding: 120px 0px 120px 0;
		@include r(1024) {
			padding: 80px 32px 80px 0; } }
	&__in {
		height: 100%; }
	&__slide {
		height: auto; }
	&__scrollbar {
		width: 9px;
		height: 75vh;
		position: absolute;
		right: 60px;
		top: 50%;
		transform: translate(0, -50%);
		margin-top: 40px;
		z-index: 10;
		@include r(1024) {
			right: 20px;
			margin-top: 0; }
		&:before {
			content: "";
			display: block;
			width: 1px;
			height: 100%;
			background: #bebebe;
			position: absolute;
			top: 0;
			left: 4px; } }
	&__drag {
		width: 19px;
		height: 100%;
		position: relative;
		left: -4px;
		top: 0;
		&:hover {
			.projectlist__handle:before {
				transform: scaleX(2);
				transform-origin: 50% 50%; } } }
	&__handle {
		@include coverdiv;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 7px;
			width: 3px;
			height: 100%;
			background: #000;
			@include trans(transform); } }
	&__next {
		text-align: center;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%); } }


.title {
	font-weight: 300;
	&__line {
		margin-top: 0.12em;
		height: .5rem;
		background: #000;
		transform: scaleX(0);
		width: 0;
		display: inline-block;
		@include xs {
			height: .22rem; } }
	&__text {
		white-space: nowrap;
		display: inline-block; }
	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&:nth-child(odd) {
			.title__line {
				transform-origin: 0 50%; }
			.title__text {
				padding-left: {} } }
		&:nth-child(even) {
			.title__line {
				transform-origin: 100% 50%; }
			.title__text {
				padding-right: {} } } } }


.parallax-img {
	width: 100%;
	height: 500px;
	position: relative;
	overflow: hidden;
	@include r(1024) {
		height: 400px; }
	@include sm {
		height: 300px; }
	@include xs {
		height: 200px; }
	@include r(375) {
		height: 150px; }
	img {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: auto; } }


.stroke {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: currentColor;
    -webkit-text-stroke-width: 1px {

	    background-position: 100%; }
    &:hover {
        -webkit-text-fill-color: black; } }

.fill {
    -webkit-text-fill-color: black;
    -webkit-text-stroke-color: currentColor;
    -webkit-text-stroke-width: 0;
    text-decoration: none !important; }


.stroke-no-hover {
    -webkit-text-fill-color: transparent;
    -webkit-text-stroke-color: currentColor;
    -webkit-text-stroke-width: 1px;
    background-position: 100%; }
