.home {
	background: #000;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	&__cover {
		@include coverdiv;
		background: #fff;
		z-index: 200; } }
.project {
	background: #fff;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;
	z-index: 1;
	&__cover {
		@include coverdiv;
		background: #000;
		z-index: 200; } }
