.header {
	&:not(.is-reversed) {
		transform: translateX(20px);
		opacity: 0; } }
.homelist {
	.swiper-container {
		transform: translateX(100%); }
	&__msg {
		transform: translateX(20px);
		opacity: 0; }
	&__handle {
		transform-origin: 0 50%; }
	&__scrollbar {
		transform: scaleX(0);
		transform-origin: 100% 50%;
		overflow: hidden; } }

.rect:not(.homelist__rect) {
	transform: scaleX(0);
	transform-origin: 100% 50%; }

.projectlist {
	&__handle {
		transform-origin: 50% 0; }
	&__scrollbar {
		transform: scaleY(0);
		transform-origin: 50% 100%;
		overflow: hidden; }
	&__slide:not(:first-child) {
		transform: translateY(20px);
		opacity: 0; } }

.home {
	&__cover {
		transform: scaleY(0);
		transform-origin: 50% 100%; } }
.project {
	&__cover {
		transform: scaleY(0);
		transform-origin: 50% 100%; } }

.title {
	transform: translateY(100vh); }

body.is-project-project {
	.project__cover {
		transform: none; } }
