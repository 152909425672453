.header {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 500;
	width: 100%;
	&.is-reversed {
		@include r(1024) {
			background: #fff; }
		.nav {
			a {
				color: #000; } } } }
.nav {
	display: flex;
	justify-content: space-between;
	padding: 40px;
	@include r(1024) {
		padding: 10px; }
	a {
		text-decoration: none;
		color: #fff;
		font-size: 14px;
		display: block;
		text-align: center;
		padding: 10px; } }
