.cursor {
	position: fixed;
	z-index: 1000;
	pointer-events: none;
	overflow: visible; }


.cursor__circle {
	background-color: #44fd82;
	width: 10px;
	height: 10px;
	color: #fff;
	border: none;
	border-radius: 100% 100%;
	position: absolute;
	overflow: hidden;
	transform: translate(-50%, -50%);
	top: 0;
	left: 0;
	transition: all 350ms cubic-bezier(0.645, 0.05, 0.355, 1);
	animation: hue-rotate 15s linear infinite;
	&.is-active {
		width: 60px;
		height: 60px; } }

.cursor__text {
	color: black;
	font-size: 14px;
	line-height: 1;
	text-align: center;
	position: absolute;
	top: 48%;
	opacity: 0;
	left: 50%;
	transform: translate(-50%, -50%);
	transition: all 200ms cubic-bezier(0.645, 0.05, 0.355, 1);
	&.is-active {
		opacity: 1; } }

@keyframes hue-rotate {
	from {
		filter: hue-rotate(0); }
	to {
		filter: hue-rotate(360deg); } }
